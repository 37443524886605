<template lang="pug">
//- TODO:テスト後ファイル名をPortfolioに変更
.portfolio#portfolio
  .l-container.is-lg.fadeup
    h2
      | WORKS
      span 受講生 / 卒業生の作品
    .portfolio-list(:class="this.current")
      .portfolio-item(v-for="list in filteredPortfolios" :key="`${list.id}`")
        lazy-component
          .video(@click="openModal(`portfolio-video-modal${list.id}`)")
            .vimeo
              video(:src="require(`~/assets/images/school/works/video/${list.id}.mp4`)" :poster="require(`~/assets/images/school/works/poster/${list.id}.png`)" autoplay muted loop playsinline loading="lazy")
              //- iframe(:src="`https://www.youtube.com/embed/${list.youtube}?autoplay=1&mute=1&playsinline=1&loop=1&playlist=${list.youtube}`" loading="lazy" title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen")
            .btn
              svg(xmlns="http://www.w3.org/2000/svg", viewBox="0 0 141 141")
                circle.cls-1(cx="70.5", cy="70.5", r="70")
            template(v-if="current === 'top'")
              .video-course.is-videographer(v-if="list.course === 'videograper'")
                | ビデオグラファーコース
              .video-course.is-motiongraphics(v-if="list.course === 'motiongraphics'")
                | モーショングラフィックスコース
        .portfolio-text
          p {{list.title}}
          p {{list.name}}
  modal(v-for="list in filteredPortfolios" :key="`${list.id}`" :name="`portfolio-video-modal${list.id}`")
    .video
      iframe(width="560" height="315" :src="`https://www.youtube.com/embed/${list.youtube}?autoplay=1&mute=1&playsinline=1&loop=1&playlist=${list.youtube}`" loading="lazy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen)
</template>

<script>
import Modal from '~/components/Modal'
export default {
  components: {
    Modal
  },
  props: {
    current: {
      type: String,
      required: true
    }
  },
  // 動画開始画面とposterの内容を合わせる
  data() {
    return {
      portfolios: [
        {
          id: 1,
          course: 'videograper',
          name: '中村 友則',
          title: 'Who is Tetsuya Goto',
          youtube: 'TB_u0XX_BqI'
        },
        {
          id: 2,
          course: 'videograper',
          name: '金 優樹',
          title: 'Taiga Igari x STAGE “Delusional Drama”',
          youtube: 'S0N-fZVvc2k'
        },
        {
          id: 3,
          course: 'videograper',
          name: '類瀬 直',
          title: '点で繋いでいく',
          youtube: 'KVNvimpAX4E'
        },
        {
          id: 4,
          course: 'videograper',
          name: '高橋 涼太',
          title: '',
          youtube: 'aXs-Pszr8bc'
        },
        {
          id: 5,
          course: 'videograper',
          name: '塩川 雄也',
          title: '渋谷に誕生、Vook 新オフィス',
          youtube: 'gLVUnrxx_QQ'
        },
        {
          id: 32,
          course: 'motiongraphics',
          name: '山口 雅人',
          title: '仮想クライアントワーク（VIDEOGRAPHERS TOKYO 2024）',
          youtube: 'R-1NApu2Www'
        },
        {
          id: 39,
          course: 'motiongraphics',
          name: 'ともぞー',
          title: '仮想クライアントワーク（VIDEOGRAPHERS TOKYO 2024）',
          youtube: 'WNLt4RaYsNY'
        },
        {
          id: 33,
          course: 'motiongraphics',
          name: 'やました まゆこ',
          title: '仮想企業イメージムービー（ポートフォリオ）',
          youtube: 'my0QA5Nmk8E'
        },
        {
          id: 38,
          course: 'motiongraphics',
          name: '荒木 智恵',
          title: '仮想クライアントワーク（VIDEOGRAPHERS TOKYO 2024）',
          youtube: 'M4dgJCDLED8'
        },
        {
          id: 37,
          course: 'motiongraphics',
          name: 'achjoa | アチュジョア',
          title:
            'ブングナイト（架空イベント）ティザームービー（ポートフォリオ）',
          youtube: 'LvgvcUp2zks'
        },
        {
          id: 36,
          course: 'motiongraphics',
          name: '大竹 聡美',
          title: '大人の食物アレルギー認知拡大動画（ポートフォリオ）',
          youtube: 's0gUcR1NzsI'
        },
        {
          id: 35,
          course: 'motiongraphics',
          name: 'こばやし ももこ',
          title: '仮想クライアントワーク（VIDEOGRAPHERS TOKYO 2024）',
          youtube: '3SQf9cxbOcI'
        },
        {
          id: 34,
          course: 'motiongraphics',
          name: '山下徹',
          title: '仮想クライアントワーク（TIPNESS）',
          youtube: 'oVFQ0GDUIHc'
        },
        {
          id: 6,
          course: 'motiongraphics',
          name: '福岡 紫苑',
          title: '仮想クライアントワーク（OHEYAGO）',
          youtube: '1t1jCldWyqo'
        },
        {
          id: 23,
          course: 'motiongraphics',
          name: 'ともぞー',
          title: 'at vookschool（ポートフォリオ）',
          youtube: '9ZV2ULV2rVE'
        },
        {
          id: 8,
          course: 'motiongraphics',
          name: 'ぽこたぽこ',
          title: 'ぽこたぽこ Lottie Animations（ポートフォリオ）',
          youtube: 'SNSa5WeIIDo'
        },
        {
          id: 9,
          course: 'motiongraphics',
          name: 'やました まゆこ',
          title: 'カリキュラム４章 中課題',
          youtube: 'J_d94dorEyc'
        },
        {
          id: 24,
          course: 'motiongraphics',
          name: 'achjoa | アチュジョア',
          title: 'ブランディングムービー（ポートフォリオ）',
          youtube: 'pRC9A8MmnwQ'
        },
        {
          id: 11,
          course: 'motiongraphics',
          name: 'monchi.',
          title: '仮想クライアントワーク（OHEYAGO）',
          youtube: 'mwM6MfbSWoE'
        },
        {
          id: 7,
          course: 'motiongraphics',
          name: '黒田 正輝',
          title: '仮想クライアントワーク（ASUS）',
          youtube: 'CZg10Nm_YJ4'
        },
        {
          id: 10,
          course: 'motiongraphics',
          name: '山下 紗季',
          title: 'カリキュラム３章 中課題',
          youtube: 'gQNnAZrr_ms'
        },
        {
          id: 25,
          course: 'motiongraphics',
          name: '林 裕人',
          title:
            '【アカペラCover】SUNKISSed GIRL/竹内アンナ【Lyric Video】（ポートフォリオ）',
          youtube: 'DhxNM_Kc_wo'
        },
        {
          id: 12,
          course: 'motiongraphics',
          name: '佐々木 健太',
          title: 'branding（ポートフォリオ）',
          youtube: 'HKaFWmb2Vs4'
        },
        {
          id: 13,
          course: 'motiongraphics',
          name: 'ふじい もと',
          title: '仮想クライアントワーク（OHEYAGO）',
          youtube: 'v3_3FOMteTY'
        },
        {
          id: 26,
          course: 'motiongraphics',
          name: '冨本 千帆',
          title: 'my likes（ポートフォリオ）',
          youtube: 'KULQvhGcWTM'
        },
        {
          id: 27,
          course: 'motiongraphics',
          name: 'ふじい もと',
          title:
            'FJ company（架空企業） 採用説明会エンディングムービー（ポートフォリオ）',
          youtube: '7e3AjgGZ4XQ'
        },
        {
          id: 28,
          course: 'motiongraphics',
          name: 'ともぞー',
          title: '踊る小悪魔スイーツ ぶDOLCE（ポートフォリオ）',
          youtube: 'zoPfS3KBKKk'
        },
        {
          id: 29,
          course: 'motiongraphics',
          name: '福岡 紫苑',
          title: '仮想クライアントワーク（SHIBUYA CITY FC）',
          youtube: '8qqQElbf6kk'
        },
        {
          id: 15,
          course: 'motiongraphics',
          name: 'achjoa | アチュジョア',
          title: 'カリキュラム２章 中課題',
          youtube: 'qnk7qoOSzd0'
        },
        {
          id: 16,
          course: 'motiongraphics',
          name: '今井 のぞみ',
          title: 'カリキュラム５章 メイン課題',
          youtube: 'HPZ-TP1fWpA'
        },
        {
          id: 17,
          course: 'motiongraphics',
          name: '中山陽子',
          title: 'カリキュラム３章 中課題',
          youtube: '9kbfVQb_xdc'
        },
        {
          id: 30,
          course: 'motiongraphics',
          name: '三宅 和恵',
          title: 'カリキュラム３章 メイン課題',
          youtube: '4Ee-nRuPtY4'
        },
        {
          id: 31,
          course: 'motiongraphics',
          name: '山下 紗季',
          title: '仮想クライアントワーク（SHIBUYA CITY FC）',
          youtube: '-OMY3ko6a30'
        },
        {
          id: 14,
          course: 'motiongraphics',
          name: '清水 大輔',
          title: '仮想クライアントワーク（GiGO）',
          youtube: 'H8-cfC_-_9g'
        },
        {
          id: 18,
          course: 'motiongraphics',
          name: '阿保鬼神',
          title: 'カリキュラム３章 小課題',
          youtube: 'Glrr29WpIAw'
        },
        {
          id: 19,
          course: 'motiongraphics',
          name: 'ぽこたぽこ',
          title: '大工のぴっぽさん（ポートフォリオ）',
          youtube: 'IgwhfsgicDc'
        },
        {
          id: 20,
          course: 'motiongraphics',
          name: '金光 貢志',
          title: 'カリキュラム３章 中課題',
          youtube: 'Cw-hzmyOSVw'
        },
        {
          id: 21,
          course: 'motiongraphics',
          name: '冨本 千帆',
          title: '仮想クライアントワーク（GiGO）',
          youtube: 'EUyLAmdN3TI'
        },
        {
          id: 22,
          course: 'motiongraphics',
          name: '金光 貢志',
          title: '仮想クライアントワーク（PIXTA）',
          youtube: '1ymsvLQE8wc'
        }
      ],
      filteredPortfolios: []
    }
  },
  mounted() {
    if (this.current === 'top') {
      const topPortfoliosId = [1, 2, 5, 6, 7, 8]
      this.filteredPortfolios = this.portfolios.filter((portfolio) => {
        return topPortfoliosId.includes(portfolio.id)
      })
    } else if (this.current === 'videographer') {
      this.filteredPortfolios = this.portfolios.filter((portfolio) => {
        return portfolio.course === 'videograper'
      })
    } else if (this.current === 'motiongraphics') {
      this.filteredPortfolios = this.portfolios.filter((portfolio) => {
        return portfolio.course === 'motiongraphics'
      })
    } else if (this.current === 'motiongraphicsPickup') {
      const motionGraphicsPortfolios = this.portfolios.filter((portfolio) => {
        return portfolio.course === 'motiongraphics'
      })
      this.filteredPortfolios = this.shuffle(motionGraphicsPortfolios).slice(
        0,
        3
      )
    } else if (this.current === 'snsLp') {
      const topPortfoliosId = [32, 23, 24, 25, 33, 27]
      this.filteredPortfolios = this.portfolios.filter((portfolio) => {
        return topPortfoliosId.includes(portfolio.id)
      })
    }
  },
  methods: {
    openModal(name) {
      this.$modal.show(name)
    },
    shuffle(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const r = Math.floor(Math.random() * (i + 1))
        const tmp = array[i]
        array[i] = array[r]
        array[r] = tmp
      }
      return array
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~/assets/css/individual/school/school2023.scss';
::v-deep {
  .v--modal-box {
    border-radius: 4px !important;
    overflow: hidden !important;
    @include media(pc) {
      width: 900px !important;
    }
    @include media(sp) {
      width: 40vmax !important;
    }
    .v-modal-content {
      padding: 0;
      margin: -1px; //小数点時の関係で1pxの隙間ができてしまうケースがあるため指定
    }
    .video {
      position: relative;
      width: 100%;
      padding-top: 56.25%;
      background: #000; //読み込みまでの背景を指定
      overflow: hidden;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }
    .v-modal-close {
      line-height: 30px;
      height: 30px;
      width: 30px;
      font-size: 18px;
      background: #fff;
      &:before {
        color: #000;
        line-height: 30px;
      }
    }
  }
}
.portfolio {
  h2 {
    margin-bottom: 50px;
    @include media(sp) {
      margin-bottom: 30px;
    }
  }
  &-list {
    @include media(pc) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @include flex_3column;
    }
    &.motiongraphics {
      @include media(pc) {
        @include flex_4column;
      }
      .portfolio-item {
        @include media(pc) {
          width: 24%;
        }
      }
    }
  }
  &-item {
    @include media(sp) {
      margin-bottom: 20px;
    }
    p {
      @include media(sp) {
        line-height: 1.5;
      }
    }
    @include media(pc) {
      width: 32%;
      margin-bottom: 3%;
    }
    .video {
      display: block;
      margin-bottom: 20px;
      position: relative;
      background: #000;
      cursor: pointer;
      &-course {
        @include course-label();
      }
      @include media(sp) {
        margin-bottom: 10px;
      }
    }
    .vimeo {
      position: relative;
      height: 0;
      padding-top: 56.25%;
      pointer-events: none;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        filter: brightness(0.8);
      }
    }
    .btn {
      width: 60px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      &:hover {
        @include media(pc) {
          svg {
            circle {
              animation: draw-circle 0.6s ease-in-out forwards;
            }
          }
        }
      }
      svg {
        circle {
          fill: none;
          stroke: #fff;
          stroke-linejoin: round;
          stroke-width: 1px;
          stroke-dasharray: 500;
          stroke-dashoffset: 0;
        }
      }
      @keyframes draw-circle {
        0% {
          stroke-dashoffset: 500px;
          opacity: 1;
        }
        60% {
          stroke-dashoffset: 0px;
          opacity: 0.3;
        }
        100% {
          opacity: 1;
        }
      }
      &:after {
        position: absolute;
        content: '';
        width: 14px;
        height: 14px;
        background: linear-gradient(
          to right bottom,
          transparent 0%,
          transparent 50%,
          #fff 50%,
          #fff 100%
        );
        top: calc(50% - 10px);
        left: calc(50% - 10px);
        transform: rotate(-45deg);
      }
    }
  }
}
</style>
